export default {
  name: "home",
  data() {
    return {
      collapsed: false,
      tagsList: []
    };
  },
  methods: {
    isActive(path) {
      return path === this.$route.path;
    },
    // 关闭单个标签
    closeTags(index) {
      const delItem = this.tagsList.splice(index, 1)[0];
      const item = this.tagsList[index]
        ? this.tagsList[index]
        : this.tagsList[index - 1];
      if (item) {
        delItem.path === this.$route.path && this.$router.push(item.path);
      } else {
        this.$router.push("/");
      }
    },
    // 设置标签
    setTags(route) {
      const isExist = this.tagsList.some(item => {
        return item.path === route.path;
      });
      if (!isExist) {
        if (route.path === "/homePage") {
          this.tagsList.unshift({
            title: route.name,
            path: route.path
          });
        } else {
          this.tagsList.push({
            title: route.name,
            path: route.path
          });
        }
      }
    },
    handleClick(e) {
      this.$router.push(e.key);
    },
    handleTags(command) {
      command.key === "other" ? this.closeOther() : this.closeAll();
    },
    // 关闭全部标签
    closeAll() {
      this.tagsList = [];
      this.$router.push("/");
      this.getTagesCor(false);
    },
    // 关闭其他标签
    closeOther() {
      const curItem = this.tagsList.filter(item => {
        return item.path === this.$route.path;
      });
      this.tagsList = curItem;
    },
    getTagesCor(params) {
      let faNames = $('.faName');
      for(let z=0;z<faNames.length;z++){
        if(faNames[z].innerHTML.trim() == this.$route.matched[0].name.trim()){
          if(params){
            faNames[z].parentNode.parentNode.style.backgroundImage="linear-gradient(135deg, #07a7e3 0%, #32dac3 100%)";
          }else{
            faNames[z].parentNode.parentNode.style.backgroundImage="none";
          }
        }else{
          faNames[z].parentNode.parentNode.style.backgroundImage="none";
        }
     }
    }
  },
  computed: {
    showTags() {
      return this.tagsList.length > 0;
    }
  },
  watch: {
    $route(newValue, oldValue) {
      this.setTags(newValue);
      this.getTagesCor(true);
    }
  },
  created() {
    this.setTags(this.$route);
  },
  mounted() {
  },
};